import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";

interface DailyMessageCount {
  date: string;
  messageCount: number;
}

const ChannelMonthlyHistogramm: React.FC = () => {
  const [data, setData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Message/GetMonthlyMessageCounts`
        );
        const messageCounts: DailyMessageCount[] = response.data;
        const formattedData = messageCounts.map((item) => item.messageCount);
        const formattedLabels = messageCounts.map(
          (item) =>
            `${new Date(item.date).toLocaleDateString().replace(".2024", "")}`
        );
        setData(formattedData);
        setLabels(formattedLabels);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Messages per Day",
        data: data,
        backgroundColor: "#0794b4",
        borderRadius: 6, // Закругление краев графиков
        barThickness: 40, // Ширина каждого столбца
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: false, // Hide Y-axis labels
        },
        grid: {
          display: false, // Hide grid lines
        },
      },
      x: {
        ticks: {
          display: true, // Show X-axis labels
          color: "#929292",
        },
        grid: {
          display: false, // Hide grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  const containerStyle: React.CSSProperties = {
    padding: "10px",
    border: "1px solid black",
    height: "20vh",
    marginTop: "auto",
    marginBottom: 0,
  };

  return (
    <div style={containerStyle} className="bordered">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ChannelMonthlyHistogramm;
