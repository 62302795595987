import React from "react";
import { ChatUser } from "../types/ChatUser";
import { Avatar, ConfigProvider, Table, Dropdown, Menu } from "antd";
import { MailOutlined, StarOutlined } from "@ant-design/icons";
import "../styles/UserRow.css";

interface UserRowProps {
  users: ChatUser[] | null;
  currentPage: number;
  pageSize: number;
  onSortChange: (sortField: string) => void;
  onRowClick: (record: ChatUser) => void;
}

const renderAvatar = (
  url: string | null,
  index: number,
  currentPage: number,
  pageSize: number
) => {
  const globalIndex = (currentPage - 1) * pageSize + index;
  let size = 40;
  let borderColor = "";

  if (currentPage === 1) {
    if (globalIndex === 0) {
      size = 80;
      borderColor = "yellow";
    } else if (globalIndex === 1) {
      size = 70;
      borderColor = "purple";
    } else if (globalIndex === 2) {
      size = 60;
      borderColor = "blue";
    } else {
      size = 50;
    }
  } else {
    size = 60;
  }

  return (
    <Avatar
      size={size}
      src={url ?? "https://api.api-ninjas.com/v1/randomimage?category=cat"}
      style={{ border: `3px solid ${borderColor}` }}
    />
  );
};

const columns = (
  currentPage: number,
  pageSize: number,
  onSortChange: (sortField: string) => void
) => [
  {
    title: "",
    dataIndex: "avatar",
    key: "avatar",
    render: (url: string | null, _record: ChatUser, index: number) =>
      renderAvatar(url, index, currentPage, pageSize),
  },
  {
    title: "Nickname",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: (
      <Dropdown
        overlay={
          <Menu onClick={(e) => onSortChange(e.key)}>
            <Menu.Item key="reputation">Reputation</Menu.Item>
            <Menu.Item key="messages">Messages</Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <span>Reputation & Messages</span>
      </Dropdown>
    ),
    dataIndex: "reputation",
    key: "reputationAndMessages",
    render: (_: any, record: ChatUser) => (
      <>
        {record.isShitEater ? (
          "💩"
        ) : (
          <>
            <StarOutlined style={{ color: "gold", marginRight: 8 }} />
            <p>{record.reputation}</p>
          </>
        )}
        <MailOutlined
          style={{ color: "grey", marginLeft: 16, marginRight: 8 }}
        />
        {record.messagesCount}
      </>
    ),
  },
  {
    title: "Last Reputation",
    dataIndex: "lastRepNickname",
    key: "lastRep",
    render: (text: string, record: ChatUser) => {
      const date = new Date(record.lastRepTime).toLocaleString();
      return (
        <span>
          {record.lastRepNickname} {date}
        </span>
      );
    },
  },
];

const UserTable: React.FC<UserRowProps> = ({
  users,
  currentPage,
  pageSize,
  onSortChange,
  onRowClick,
}) => {
  if (!users) {
    return null; // Early return if users === null
  }

  const rowClassName = (record: ChatUser, index: number) => {
    return ""; // Class name logic for rows, if needed
  };

  return (
    <ConfigProvider>
      <div style={{ overflow: "auto", border: "1px black solid" }}>
        <Table
          columns={columns(currentPage, pageSize, onSortChange)}
          dataSource={users}
          rowKey="id"
          pagination={false}
          rowClassName={rowClassName}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          scroll={{ y: "50vh" }} // добавил вертикальный скролл
        />
      </div>
    </ConfigProvider>
  );
};

export default UserTable;
