import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { Flex } from "antd";

interface HourlyAverageMessageCount {
  hour: number;
  averageMessageCount: number;
}

const ChannelDailyHistogramm: React.FC = () => {
  const [data, setData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Message/GetDailyMessageCounts`
        );
        const messageCounts: HourlyAverageMessageCount[] = response.data;
        const formattedData = messageCounts.map(
          (item) => item.averageMessageCount
        );
        const formattedLabels = messageCounts.map((item) => `${item.hour}:00`);
        setData(formattedData);
        setLabels(formattedLabels);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Average Messages per Hour",
        data: data,
        backgroundColor: "#0794b4",
        borderRadius: 6, // Закругление краев графиков
        barThickness: 10, // Ширина каждого столбца
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          display: false, // Hide Y-axis labels
        },
        grid: {
          display: false, // Hide grid lines
        },
      },
      x: {
        ticks: {
          display: true, // Show X-axis labels
          color: "#929292",
        },
        grid: {
          display: false, // Hide grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  const containerStyle: React.CSSProperties = {
    padding: "10px",
    margin: "10px",
    width: "20%",
    border: "1px solid black",
  };

  const currentHour = () => {
    const now = new Date();
    return now.getHours();
  };

  return (
    <div style={containerStyle} className="bordered">
      <Flex
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <h2> Daily average</h2>
        <div style={{ height: "15vh" }}>
          <Bar data={chartData} options={options} />
        </div>
        <Flex style={{ flexDirection: "row", marginTop: "10px" }}>
          <div style={{ marginLeft: "10%", marginRight: "5%", width: "20%" }}>
            <h5>Prev</h5>
            <p
              style={{
                marginTop: -15,
                border: "1px black solid",
                padding: "18px",
              }}
            >
              {data.at(currentHour() - 1)?.toFixed()}
            </p>
          </div>
          <div style={{ marginInline: "5%", width: "20%" }}>
            <h5>Current</h5>
            <p
              style={{
                marginTop: -15,
                border: "1px black solid",
                padding: "18px",
              }}
            >
              {data.at(currentHour())?.toFixed()}
            </p>
          </div>
          <div style={{ marginLeft: "5%", marginRight: "10%", width: "20%" }}>
            <h5>Next</h5>
            <p
              style={{
                marginTop: -15,
                border: "1px black solid",
                padding: "18px",
              }}
            >
              {data.at(currentHour() + 1)?.toFixed()}
            </p>
          </div>
        </Flex>
      </Flex>
    </div>
  );
};

export default ChannelDailyHistogramm;
