import React from "react";
import osuIcon from "../img/icon-osu.png";
import "../styles/Header.css";

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="header-left">
        <img alt="Icon osu" src={osuIcon} className="osu-icon" />
        <div className="header-text" style={{color: 'white'}}>#russian</div>
      </div>
      <div className="header-right"></div>
    </div>
  );
};

export default Header;
