import React from "react";
import { Modal, Avatar, Flex } from "antd";
import { ChatUser } from "../types/ChatUser";
import "../styles/UserModal.css";
import { MailOutlined, StarOutlined } from "@ant-design/icons";

interface UserModalProps {
  visible: boolean;
  user: ChatUser | null;
  onClose: () => void;
}

const UserModal: React.FC<UserModalProps> = ({ visible, user, onClose }) => {
  if (!user) {
    return null;
  }

  return (
    <Modal
      width={900}
      visible={visible}
      onCancel={onClose}
      footer={null}
      className="user-modal"
    >
      <div className="user-info">
        <Avatar size={211} src={user.avatar} className="user-avatar" />
        <div className="user-details">
          <Flex justify="space-between">
            <div className="user-nickname">{user.nickname}</div>
            <div className="user-rank">#{user.rank.toString()}</div>
          </Flex>
          <hr className="hr" />
          <Flex justify="space-between">
            <Flex>
              <div className="user-reputation">
                <StarOutlined style={{ color: "gold", marginRight: 8 }} />
                {user.reputation}
                <MailOutlined
                  style={{ color: "grey", marginLeft: 16, marginRight: 8 }}
                />
                {user.messagesCount}
              </div>
            </Flex>

            <div
              className="user-pp"
              style={{ marginTop: 0, marginBottom: "auto" }}
            >
              ({user.osuMode}) {user.pp} pp
            </div>
          </Flex>
          <Flex justify="space-between">
            <div style={{ width: 300 }}>
              <h2>Статы</h2>
              <hr
                className="hr"
                style={{ marginBottom: "-12px", marginTop: "-12px" }}
              />
              <h3>Акк {user.accuracy}%</h3>
              <Flex justify="space-between">
                <div className="user-level">Lvl: {user.level.toString()}</div>
                <Flex style={{ flexDirection: "column" }}>
                  <div className="user-play-count">
                    {user.playCount.toString()} пк
                  </div>
                  <div className="user-play-time">{user.playTime} часов</div>
                </Flex>
              </Flex>
            </div>
            <a
              href={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"}
              className="profile-link"
            >
              Profile
            </a>
          </Flex>
        </div>
      </div>
    </Modal>
  );
};

export default UserModal;
