import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import { ChatUser } from "../types/ChatUser";
import UserTable from "./UserTable";
import ConfigProvider from "antd/es/config-provider";
import darkTheme from "../theme";
import { Flex, Pagination } from "antd";
import UserModal from "./UserModal";
import ChannelMonthlyHistogramm from "./ChannelMonthlyHistogramm";
import ChannelDailyHistogramm from "./ChannelDailyHistogramm";
import Header from "./Header";

const Main: React.FC = () => {
  const [users, setUsers] = useState<ChatUser[] | null>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState<string | null>("reputation");
  const [selectedUser, setSelectedUser] = useState<ChatUser | null>(null);

  useEffect(() => {
    fetchUsers(currentPage, pageSize, sortField);
  }, [currentPage, pageSize, sortField]);

  const fetchUsers = async (
    pageNumber: number,
    pageSize: number,
    sortField: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Users/GetUsers`,
        {
          params: {
            pageNumber: pageNumber,
            pageSize: pageSize,
            sortField: sortField,
          },
        }
      );
      setUsers(response.data.data);
      setTotalRecords(response.data.totalRecords);
    } catch (error) {
      console.error("Ошибка при загрузке пользователей:", error);
      setUsers([]); // Можно установить пустой массив при ошибке, если это подходит для вашего случая
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleSortChange = (sortField: string) => {
    setSortField(sortField);
  };

  const handleRowClick = (record: ChatUser) => {
    setSelectedUser(record);
  };

  const handleModalClose = () => {
    setSelectedUser(null);
  };

  return (
    <div className="App">
      <ConfigProvider theme={darkTheme}>
        <header>
          <Header />
        </header>
        <Flex>
          <div style={{ width: "80%", margin: "10px" }}>
            <UserTable
              users={users}
              currentPage={currentPage}
              pageSize={pageSize}
              onSortChange={handleSortChange}
              onRowClick={handleRowClick}
            />
            <Pagination
              style={{ marginTop: "5px" }}
              current={currentPage}
              pageSize={pageSize}
              total={totalRecords}
              align="end"
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageChange}
            />
          </div>
          <ChannelDailyHistogramm />
        </Flex>
        <ChannelMonthlyHistogramm />
        <UserModal
          visible={!!selectedUser}
          user={selectedUser}
          onClose={handleModalClose}
        />
        <footer>
          <p>
            by <a href="https://osu.ppy.sh/users/13928601">Dixxew</a>
          </p>
        </footer>
      </ConfigProvider>
    </div>
  );
};

export default Main;
