import { ThemeConfig } from 'antd/es/config-provider/context';

const darkTheme: ThemeConfig = {
  token: {
    colorPrimary: '#1890ff',
    colorBgBase: '#0D1114',
    colorTextBase: '#ffffff',
  },
};

export default darkTheme;
